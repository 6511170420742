
import { computed, defineComponent, watch } from 'vue'
import Sidebar from '@/components/Sidebar.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
	name: 'LayoutDefault',
	components: { Sidebar },
	setup() {
		const route = useRoute()
		const breadcrumbs = computed(() => {
			return route.matched.map((r) => {
				const bread = r.meta
				if (bread.breadcrumb && typeof bread.breadcrumb === 'function') {
					return bread.breadcrumb({ title: route.params[bread.paramsKey as string], path: route.path })
				}
				return bread.breadcrumb
			})
		})
		
		return {
			route,
			breadcrumbs
		}
	}
})
